<template>
    <LoginDialog :title="$t('$vuetify.login_registe_title')" @close="closeHandler">
        <div class="tk-p-h-30 tk-p-t-50">
            <div class="">
                <CustomInput ref="accountRef" :title="$t('$vuetify.login_account_title')"
                    :placeholder="$t('$vuetify.login_account_title')" v-model="email" :rules="accountRule">
                </CustomInput>
            </div>
            <!-- <div class="tk-h-5"></div> -->
            <div>
                <CustomInput :title="$t('$vuetify.login_password_title')" ref="passwordRef" type="password"
                    :rules="passwordRule" v-model="password" :placeholder="$t('$vuetify.login_password_title')">
                </CustomInput>
            </div>
            <div>
                <CustomInput :can-hide="true" :show-input="false" type="text" v-model="inviteCode"
                    :title="$t('$vuetify.login_invite_title')">
                </CustomInput>
            </div>
            <CustomCheckBox v-model="ageAgree" :btn-text="$t('$vuetify.login_agree_btn_title')"
                :title="$t('$vuetify.login_agree_title')" @handler="handlerAction"></CustomCheckBox>
            <div class="tk-h-5"></div>
            <CustomCheckBox v-model="agreeActivity" :title="$t('$vuetify.login_activity_title')"></CustomCheckBox>
            <div class="tk-m-t-50">
                <CustomButton @handler="registerHandler" :title="$t('$vuetify.btn_register')"></CustomButton>
            </div>
            <div class="tk-p-t-20 flex-row flex-align-center">
                <span class="tk-fs-14">{{ $t('$vuetify.login_account_ready_title') }}</span>
                <div @click="toLogin" class="color-title-btn tk-fs-15 pointer tk-p-l-10">{{ $t('$vuetify.btn_login') }}
                </div>
            </div>
        </div>
        <div style="margin-top: auto;" class="tk-p-b-15">
            <CustomGuide :title="$t('$vuetify.login_read_login_title')"></CustomGuide>
            <div class="flex-row flex-center tk-p-v-10" style="gap: 0.5rem;">
                <div class="pointer" data-type="icon" id="g_id_onload"
                    data-client_id="8blq1p68iu4r9bacf8nuv3es3nmsjekm.apps.googleusercontent.com "
                    data-callback="handleToken" data-context="signin" data-your_own_param_1_to_login="any_value"
                    data-your_own_param_2_to_login="any_value">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
                <div class="pointer">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
                <div class="pointer" @click="toConnectMetaMask(2)">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
                <div class="pointer" @click="toConnectMetaMask(1)">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
            </div>
        </div>
    </LoginDialog>
</template>


<script setup lang="ts">

import CustomButton from '@/components/input/CustomButton.vue';
import CustomInput from '@/components/input/CustomInput.vue';
import { $t } from '@/locale';
import { ref } from 'vue';
import CustomCheckBox from '@/components/input/CustomCheckBox.vue';
import CustomGuide from '@/components/input/CustomGuide.vue';
import { clientApi } from '@/api';
import { showLoading, ShowMessage } from '../tips';
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';
import router from '@/router';
import LoginDialog from './loginDialog.vue';
import { walletClient } from '@/plugins/wallet';

const emits = defineEmits(['close'])
defineProps<{

}>()
const closeHandler = () => {
    emits('close')
}

const email = ref("")
const password = ref("")
const inviteCode = ref("")
const ageAgree = ref(true)
const agreeActivity = ref(true)
const accountRule = (value: string) => {
    if (value.length > 6) {
        return;
    }
    return $t('$vuetify.login_account_rule_title')
}
const passwordRule = (value: string) => {
    if (value.length > 5) {
        return;
    }
    return $t('$vuetify.login_password_rule_title')
}



const accountRef = ref()
const passwordRef = ref()
const registerHandler = async (e: Function) => {
    if (!accountRef.value.validate()) {
        e();
        return;
    }
    if (!passwordRef.value.validate()) {
        e();
        return;
    }
    try {
        const token = await clientApi.userApi.registerByEmailNoCodeWeb<string>({
            email: email.value,
            password: password.value,
            repassword: password.value,
            inviteCode: inviteCode.value
        });
        closeHandler();
        EventInstance.emit(EventCustomType.LoginSuccess, token);
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}


const toLogin = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToLogin)
}

const handlerAction = () => {
    closeHandler();
    router.push("/help/prival")
}

/** connect metamask */
const toConnectMetaMask = async (type: number) => {
    console.log(type);
    const loading = showLoading('connecting');
    try {
        const status = await walletClient.connectWalletByType(type);
        if (!status) {
            ShowMessage("Disconnect Error");
        }
    } catch (error) {
        console.log(error);

        if (typeof error === 'string') {
            ShowMessage(error);
        }
        console.log(error);
    }
    loading?.close();
}

</script>


<style lang="scss" scoped>
.content {
    height: 716px;
    font-weight: 600;
    position: relative;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
}
</style>