import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import protocol from './module/protocol'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/layout/main.vue"),
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home/home.vue"),
      },
      {
        path: "menu",
        component: () => import("@/views/menu/menu.vue"),
      },
      protocol
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
