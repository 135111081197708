import { createI18n } from 'vue-i18n'
import { en, zhHans, es } from 'vuetify/locale'
import zhCN from './module/zh'
import enLocal from './module/en'
import esLocal from "./module/es"
export type LanguageType = "zhHans" | "en" | 'es'
export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    fallbackLocale: "en",
    locale: 'zhHans', // 默认显示语言
    messages: {
        zhHans: {
            '$vuetify': {
                ...zhHans,
                ...zhCN
            }
        },
        en: {
            '$vuetify': {
                ...en,
                ...enLocal
            }
        },
        es: {
            '$vuetify': {
                ...es,
                ...esLocal
            }
        }
    }
})

export const $t = i18n.global.t;
export function setLocale(locale: LanguageType) {
    i18n.global.locale.value = locale;
}