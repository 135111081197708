import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tk-p-h-30 tk-p-t-50" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "tk-m-t-50" }
const _hoisted_4 = { class: "tk-p-t-20 flex-row flex-align-center" }
const _hoisted_5 = { class: "tk-fs-14" }
const _hoisted_6 = {
  style: {"margin-top":"auto"},
  class: "tk-p-b-15"
}
const _hoisted_7 = {
  class: "flex-row flex-center tk-p-v-10",
  style: {"gap":"0.5rem"}
}
const _hoisted_8 = {
  class: "pointer",
  "data-type": "icon",
  id: "g_id_onload",
  "data-client_id": "8blq1p68iu4r9bacf8nuv3es3nmsjekm.apps.googleusercontent.com ",
  "data-callback": "handleToken",
  "data-context": "signin",
  "data-your_own_param_1_to_login": "any_value",
  "data-your_own_param_2_to_login": "any_value"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "pointer" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]

import CustomButton from '@/components/input/CustomButton.vue';
import CustomInput from '@/components/input/CustomInput.vue';
import { $t } from '@/locale';
import { ref } from 'vue';
import CustomCheckBox from '@/components/input/CustomCheckBox.vue';
import CustomGuide from '@/components/input/CustomGuide.vue';
import { clientApi } from '@/api';
import { showLoading, ShowMessage } from '../tips';
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';
import router from '@/router';
import LoginDialog from './loginDialog.vue';
import { walletClient } from '@/plugins/wallet';


export default /*@__PURE__*/_defineComponent({
  __name: 'register',
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {


const emits = __emit

const closeHandler = () => {
    emits('close')
}

const email = ref("")
const password = ref("")
const inviteCode = ref("")
const ageAgree = ref(true)
const agreeActivity = ref(true)
const accountRule = (value: string) => {
    if (value.length > 6) {
        return;
    }
    return $t('$vuetify.login_account_rule_title')
}
const passwordRule = (value: string) => {
    if (value.length > 5) {
        return;
    }
    return $t('$vuetify.login_password_rule_title')
}



const accountRef = ref()
const passwordRef = ref()
const registerHandler = async (e: Function) => {
    if (!accountRef.value.validate()) {
        e();
        return;
    }
    if (!passwordRef.value.validate()) {
        e();
        return;
    }
    try {
        const token = await clientApi.userApi.registerByEmailNoCodeWeb<string>({
            email: email.value,
            password: password.value,
            repassword: password.value,
            inviteCode: inviteCode.value
        });
        closeHandler();
        EventInstance.emit(EventCustomType.LoginSuccess, token);
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}


const toLogin = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToLogin)
}

const handlerAction = () => {
    closeHandler();
    router.push("/help/prival")
}

/** connect metamask */
const toConnectMetaMask = async (type: number) => {
    console.log(type);
    const loading = showLoading('connecting');
    try {
        const status = await walletClient.connectWalletByType(type);
        if (!status) {
            ShowMessage("Disconnect Error");
        }
    } catch (error) {
        console.log(error);

        if (typeof error === 'string') {
            ShowMessage(error);
        }
        console.log(error);
    }
    loading?.close();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LoginDialog, {
    title: _unref($t)('$vuetify.login_registe_title'),
    onClose: closeHandler
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(CustomInput, {
            ref_key: "accountRef",
            ref: accountRef,
            title: _unref($t)('$vuetify.login_account_title'),
            placeholder: _unref($t)('$vuetify.login_account_title'),
            modelValue: email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            rules: accountRule
          }, null, 8, ["title", "placeholder", "modelValue"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(CustomInput, {
            title: _unref($t)('$vuetify.login_password_title'),
            ref_key: "passwordRef",
            ref: passwordRef,
            type: "password",
            rules: passwordRule,
            modelValue: password.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            placeholder: _unref($t)('$vuetify.login_password_title')
          }, null, 8, ["title", "modelValue", "placeholder"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(CustomInput, {
            "can-hide": true,
            "show-input": false,
            type: "text",
            modelValue: inviteCode.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((inviteCode).value = $event)),
            title: _unref($t)('$vuetify.login_invite_title')
          }, null, 8, ["modelValue", "title"])
        ]),
        _createVNode(CustomCheckBox, {
          modelValue: ageAgree.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ageAgree).value = $event)),
          "btn-text": _unref($t)('$vuetify.login_agree_btn_title'),
          title: _unref($t)('$vuetify.login_agree_title'),
          onHandler: handlerAction
        }, null, 8, ["modelValue", "btn-text", "title"]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "tk-h-5" }, null, -1)),
        _createVNode(CustomCheckBox, {
          modelValue: agreeActivity.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((agreeActivity).value = $event)),
          title: _unref($t)('$vuetify.login_activity_title')
        }, null, 8, ["modelValue", "title"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(CustomButton, {
            onHandler: registerHandler,
            title: _unref($t)('$vuetify.btn_register')
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_unref($t)('$vuetify.login_account_ready_title')), 1),
          _createElementVNode("div", {
            onClick: toLogin,
            class: "color-title-btn tk-fs-15 pointer tk-p-l-10"
          }, _toDisplayString(_unref($t)('$vuetify.btn_login')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(CustomGuide, {
          title: _unref($t)('$vuetify.login_read_login_title')
        }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_9)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_11)
          ]),
          _createElementVNode("div", {
            class: "pointer",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (toConnectMetaMask(2)))
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_12)
          ]),
          _createElementVNode("div", {
            class: "pointer",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (toConnectMetaMask(1)))
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_13)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})