const local = {
    btn_login: 'Login',
    btn_register: 'Register',
    btn_exit: 'Exit',
    login_account_title: 'Email address or username',
    login_account_rule_title: "Please enter your email address",
    login_password_title: "Password",
    login_password_rule_title: "Please enter your password (no less than 6 digits)",
    login_invite_title: "Referral code",
    login_agree_title: "I agree to [User Agreement] and confirm that I am over 18 years old",
    login_agree_btn_title: "User Agreement",
    login_activity_title: "I agree to accept the platform's promotional activities",
    login_account_ready_title: "Already have an account, please log in",
    login_read_login_title: "Log in directly",
    login_registe_title: "Register",
    login_forget_title: "Forgot password",
    login_email_code: 'Email verification code',
    login_get_email_code: "Get verification code",
    login_send_email_code: "Sent successfully",
    login_back_login: "Back to login",
    menu_casino: "Casino",
    menu_leisure: "Leisure",
    menu_shopping: 'Shopping',
    menu_pledge: 'Pledge',
    menu_vip: 'VIP',
    menu_bonus: 'Bonus',
    menu_recommend_reward: 'Recommendation reward',
    menu_reasonable: 'Fair and reasonable',
    menu_blog: "Blog",
    menu_forum: 'Forum',
    menu_sponsor: 'Sponsorship',
    menu_online_service: 'Online Customer Service',
    menu_language: 'Traditional Chinese',
    menu_en_language: "English",
    menu_es_language: "Español",
    menu_horse: 'Horse Racing',
    menu_up_down: 'UP DOWN Options',
    menu_bing_guo: "Baoguo",
}

export default local