import { ERCConfig } from "@/env";
import { EventInstance } from "@/event";
import { EventCustomType } from "@/event/type";
import { XWalletConnect } from "x-wallet";


export default class WalletConnectWalletType extends XWalletConnect {

    getRightChainHost() {
        return ERCConfig.chainHost;
    }

    getRightChainId() {
        return parseInt(ERCConfig.defaultChain);
    }



    listenAccountChanged(accounts: string[]) {
        console.log("account==>", accounts);
        EventInstance.emit(EventCustomType.AccountChangeWallet, accounts);
    }

    listenChainIdChanged(chainId: number) {

    }

    // @ts-ignore
    getDisRequestType() {
        return true;
    }

}