import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-row flex-center content" }
const _hoisted_2 = { class: "tk-fs-14" }


export default /*@__PURE__*/_defineComponent({
  __name: 'message',
  props: {
    text: {},
    type: { default: 'success' }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const emits = __emit


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["toast-message animate__fadeInDown animate__animated", ['toast-' + _ctx.type]])
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1)
    ], 2)
  ]))
}
}

})