class EventNotification {
    private functionMap: Map<string, Function[]> = new Map();
    private functionOnceMap: Map<string, Function[]> = new Map();
    constructor() {

    }

    /** 監聽 */
    on(eventName: string, callback: Function) {
        if (!this.functionMap.get(eventName)) {
            this.functionMap.set(eventName, []);
        }
        if (Array.isArray(this.functionMap.get(eventName))) {
            this.functionMap.get(eventName)?.push(callback)
        }
    }

    /** 發送事件 */
    emit(eventName: string, ...arg: any) {
        if (this.functionMap.has(eventName)) {
            const list = this.functionMap.get(eventName) ?? [];
            for (let i = 0; list.length > i; i++) {
                list[i](...arg);
            }
        }
        if (this.functionOnceMap.has(eventName)) {
            const list = this.functionMap.get(eventName)!;
            for (let i = 0; list.length > i; i++) {
                list[i](...arg);
            }
            this.functionOnceMap.delete(eventName)
        }
    }

    /** 監聽一次 */
    once(eventName: string, callback: Function) {
        if (!this.functionMap.get(eventName)) {
            this.functionMap.set(eventName, []);
        }
        this.functionMap.get(eventName)?.push(callback)
    }

    /** 移除監聽 */
    off(eventName: string, callback: Function) {
        if (this.functionMap.get(eventName)) {
            if (callback) {
                const list = this.functionMap.get(eventName) ?? [];
                const toList = [];
                for (let index = 0; index < list.length; index++) {
                    const call = list[index];
                    if (call.toString() !== callback.toString()) {
                        toList.push(call);
                    }
                }
                if (toList.length > 0) {
                    this.functionMap.set(eventName, toList);
                } else {
                    this.functionMap.delete(eventName)
                }
            }
        }
    }
}

export const EventInstance = new EventNotification();

