import { Md5 } from "@/plugins/encrpty/crypto";
import { BaseApi } from "./base";


export class UserApi extends BaseApi {

    /** 註冊 */
    registerByEmailNoCodeWeb<T>(options: {
        nickname?: string
        password: string
        repassword: string
        email: string
        inviteCode?: string
    }): Promise<T> {
        options.nickname = options.nickname || "NickName";
        if (!options.inviteCode) {
            delete options.inviteCode;
        }
        options.password = Md5(options.password);
        options.repassword = Md5(options.repassword);
        return this.post("/yluser/user/web/userEmialWeb/registerByEmailNoCodeWeb", options);
    }
    /** 登錄 */
    webLogin<T>(loginSign: string, password: string): Promise<T> {
        return this.post("/yluser/user/web/userEmialWeb/webLogin", { loginSign, password: Md5(password) });
    }

    /** 發送郵箱驗證碼 */
    sendCodeByEmail(email: string) {
        return this.post("/yluser/user/web/userEmialWeb/sendCodeByEmail", { email })
    }

    /** 邮箱重置密码 */
    resetPasswordByEmail(options: {
        email: string
        code: string
        password: string
        repassword: string
    }) {
        return this.post("/yluser/user/web/userEmialWeb/resetPasswordByEmail", options)
    }

    /** 錢包登錄 */
    walletAddressLogin(options: {
        account: string
        channel?: string
        timeStamp: number,
        signature: string,
        inviteCode?: string,
        setId?: string
    }) {
        console.log(options);
        return this.post("/yluser/user/web/userWeb/walletAddressLogin", options);
    }

    /** 獲取用戶信息 */
    getUserInfo() {
        return this.post("/yluser/user/web/userWeb/getUserInfo", {});
    }

    /** google 登錄 */
    gmailLogin(options: {
        idToken: string,
        inviteCode?: string,
        userRoleType?: 'online' | 'offline'
    }) {
        return this.post("/yluser/user/web/userWeb/gmailLogin", options);
    }
}