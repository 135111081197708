import { createVNode, render, VNode } from "vue";
import LoginVue from './login.vue'
import RegisterVue from './register.vue'
import ForgetView from './forget.vue'

let loginStatus = false;
export const LoginDialog = () => {
    if (loginStatus) return;
    loginStatus = true;
    const body = document.body;
    let vm: VNode | null = createVNode(LoginVue, {
        onClose() {
            loginStatus = false;
            if (vm && vm.el) {
                body.removeChild(<Node>vm.el);
            }
            vm = null;
        }
    })
    const content = document.createElement("div");
    render(vm, content);
    body.appendChild(<Node>vm.el);
}


let registerStatus = false;
export const RegisterDialog = () => {
    if (registerStatus) return;
    registerStatus = true;
    const body = document.body;
    let vm: VNode | null = createVNode(RegisterVue, {
        onClose() {
            registerStatus = false;
            if (vm && vm.el) {
                body.removeChild(<Node>vm.el);
            }
            vm = null;
        }
    })
    const content = document.createElement("div");
    render(vm, content);
    body.appendChild(<Node>vm.el);
}


let forgetStatus = false;
export const ForgetViewDialog = () => {
    if (forgetStatus) return;
    forgetStatus = true;
    const body = document.body;
    let vm: VNode | null = createVNode(ForgetView, {
        onClose() {
            forgetStatus = false;
            if (vm && vm.el) {
                body.removeChild(<Node>vm.el);
            }
            vm = null;
        }
    })
    const content = document.createElement("div");
    render(vm, content);
    body.appendChild(<Node>vm.el);
}










