import { RouteRecordRaw } from "vue-router";

export default {
    path: "help",
    component: () => import("@/views/help/child.vue"),
    redirect: "/help/prival",
    children: [{
        path: 'prival',
        component: () => import("@/views/help/private.vue"),
    }, {
        path: "service",
        component: () => import('@/views/help/service.vue')
    }]

} as RouteRecordRaw