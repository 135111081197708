const local = {
    btn_login: 'Iniciar sesión',
    btn_register: 'Registrarse',
    btn_exit: 'Salir',
    login_account_title: 'Dirección de correo electrónico o nombre de usuario',
    login_account_rule_title: "Por favor ingrese su dirección de correo electrónico",
    login_password_title: "Contraseña",
    login_password_rule_title: "Por favor ingrese su contraseña (no menos de 6 dígitos)",
    login_invite_title: "Código de recomendación",
    login_agree_title: "Acepto el [Acuerdo de usuario] y confirmo que soy mayor de 18 años",
    login_agree_btn_title: "Acuerdo de usuario",
    login_activity_title: "Acepto las promociones de la plataforma",
    login_account_ready_title: "Ya tienes una cuenta, inicia sesión",
    login_read_login_title: "Iniciar sesión directamente",
    login_registe_title: "Registro",
    login_forget_title: "Olvidé mi contraseña",
    login_email_code: 'Código de verificación de correo electrónico',
    login_get_email_code: "Obtener código de verificación",
    login_send_email_code: "Enviar exitosamente",
    login_back_login: "Volver al inicio de sesión",
    menu_casino: "Casino",
    menu_leisure: "Ocio",
    menu_shopping: 'Compras',
    menu_pledge: 'Promesa',
    menu_vip: 'VIP',
    menu_bonus: 'Bonificación',
    menu_recommend_reward: 'Recompensa por recomendación',
    menu_reasonable: 'Justo y razonable',
    menu_blog: "Blog",
    menu_forum: 'Foro',
    menu_sponsor: 'Patrocinio',
    menu_online_service: 'Servicio al cliente en línea',
    menu_language: 'Chino tradicional',
    menu_en_language: "inglés",
    menu_es_language: "Español",
    menu_horse: 'carreras de caballos',
    menu_up_down: 'opción ARRIBA ABAJO',
    menu_bing_guo: "Baoguo"
}

export default local;