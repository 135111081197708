const local = {
    btn_login: '登錄',
    btn_register: '註冊',
    btn_exit: '退出',
    login_account_title: '电邮地址或用户名',
    login_account_rule_title: "請輸入郵箱",
    login_password_title: "密码",
    login_password_rule_title: "請輸入密碼(不少於6位)",
    login_invite_title: "推荐码",
    login_agree_title: "我同意[用戶協議] 並確認我已滿18歲",
    login_agree_btn_title: "用戶協議",
    login_activity_title: "我同意接受平台的促銷活動",
    login_account_ready_title: "已有账号，请登录",
    login_read_login_title: "直接登入",
    login_registe_title: "注册",
    login_forget_title: "忘記密碼",
    login_email_code: '郵箱驗證碼',
    login_get_email_code: "獲取驗證碼",
    login_send_email_code: "發送成功",
    login_back_login: "返回登錄",
    login_create_new_account: '你是FCGAME的新手麽?',
    login_create_account: '建立賬號',
    menu_casino: "賭場",
    menu_leisure: "休閒",
    menu_shopping: '購物',
    menu_pledge: '質押',
    menu_vip: 'VIP',
    menu_bonus: '獎金',
    menu_recommend_reward: '推薦獎勵',
    menu_reasonable: '公平合理',
    menu_blog: "博客",
    menu_forum: '論壇',
    menu_sponsor: '贊助',
    menu_online_service: '線上客服',
    menu_language: '繁體中文',
    menu_en_language: "English",
    menu_es_language: "Español",
    menu_horse: '賽馬',
    menu_up_down: 'UP DOWN期權',
    menu_bing_guo: "寶果",
    coin_token: "Token"
}

export default local;