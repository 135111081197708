<template>
    <LoginDialog :title="$t('$vuetify.login_forget_title')" @close="$emit('close')">
        <div class="tk-p-h-30 tk-p-t-50">
            <div class="">
                <CustomInput :title="$t('$vuetify.login_account_title')" ref="emailRef" v-model="email"
                    :placeholder="$t('$vuetify.login_account_title')" :rules="accountRule">
                </CustomInput>
            </div>
            <div class="tk-h-1"></div>
            <div class="flex-row flex-align-center">
                <CustomInput :title="$t('$vuetify.login_email_code')" style="flex: 1 1 0;" ref="emailCodeRef"
                    v-model="emailCode" :placeholder="$t('$vuetify.login_email_code')" :rules="emailRule">
                    <template #btn>
                        <CustomButton class="tk-m-l-5 tk-fs-12" @handler="getEmailCode"
                            :title="$t('$vuetify.login_get_email_code')"></CustomButton>
                    </template>
                </CustomInput>
            </div>
            <div class="tk-h-1"></div>
            <div>
                <CustomInput :title="$t('$vuetify.login_password_title')" ref="passwordRef" type="password"
                    :rules="passwordRule" v-model="password" :placeholder="$t('$vuetify.login_password_title')">
                </CustomInput>
            </div>
            <div class="w-1 flex-row">
                <div class="pointer" @click="clickToLogin">
                    <span class="tk-fs-14 color-title-btn">{{ $t('$vuetify.login_back_login') }}</span>
                </div>
            </div>
            <div class="tk-m-t-50">
                <CustomButton @handler="forgetHandler" :title="$t('$vuetify.login_forget_title')"></CustomButton>
            </div>
        </div>
    </LoginDialog>
</template>


<script setup lang="ts">
import CustomButton from '@/components/input/CustomButton.vue';
import CustomInput from '@/components/input/CustomInput.vue';
import { $t } from '@/locale';
import { ref } from 'vue';
import { clientApi } from '@/api';
import { ShowMessage } from '../tips';
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';
import LoginDialog from './loginDialog.vue';
const emits = defineEmits(['close'])
defineProps<{

}>()
const closeHandler = () => {
    emits('close')
}

const email = ref("")
const emailCode = ref("")
const password = ref("")
const accountRule = (value: string) => {
    if (value.length > 6) {
        return;
    }
    return $t('$vuetify.login_account_rule_title')
}
const passwordRule = (value: string) => {
    if (value.length > 5) {
        return;
    }
    return $t('$vuetify.login_password_rule_title')
}
const emailRule = (value: string) => {
    if (value.length > 3) {
        return;
    }
    return $t('$vuetify.login_email_code')
}

const emailRef = ref()
const emailCodeRef = ref()
const passwordRef = ref()
/** 獲取郵箱驗證碼 */
const getEmailCode = async (e: Function) => {
    if (!emailRef.value.validate()) {
        e();
        return;
    }
    try {
        await clientApi.userApi.sendCodeByEmail(email.value);
        ShowMessage($t('$vuetify.login_send_email_code'), 'success')
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}

/** 確認重置密碼 */
const forgetHandler = async (e: Function) => {
    if (!emailRef.value.validate() ||
        !emailCodeRef.value.validate() ||
        !passwordRef.value.validate()) {
        e();
        return;
    }
    try {
        await clientApi.userApi.resetPasswordByEmail({
            email: email.value,
            code: emailCode.value,
            password: password.value,
            repassword: password.value
        });
        closeHandler();
        EventInstance.emit(EventCustomType.LoginToLogin);
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}

/** 返回登錄 */
const clickToLogin = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToLogin);
}

</script>


<style lang="scss" scoped>
.content {
    height: 716px;
    font-weight: 600;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
}
</style>