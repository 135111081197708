import { createApp } from 'vue'
import "@/style/index.scss"
import App from './App.vue'
import router from './router'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { createPinia } from 'pinia'
import { i18n } from './locale'
import { useI18n } from 'vue-i18n'
import components from './components'

import "@/common/resize"
import "@/plugins/bus/index"

createApp(App).use(i18n).use(components).use(createPinia()).use(createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        }
    },
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n })
    }
})).use(router).mount('#app')
