import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tk-p-h-30 tk-p-t-50" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "flex-row flex-align-center" }
const _hoisted_4 = { class: "w-1 flex-row" }
const _hoisted_5 = { class: "tk-fs-14 color-title-btn" }
const _hoisted_6 = { class: "tk-m-t-50" }

import CustomButton from '@/components/input/CustomButton.vue';
import CustomInput from '@/components/input/CustomInput.vue';
import { $t } from '@/locale';
import { ref } from 'vue';
import { clientApi } from '@/api';
import { ShowMessage } from '../tips';
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';
import LoginDialog from './loginDialog.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'forget',
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const emits = __emit

const closeHandler = () => {
    emits('close')
}

const email = ref("")
const emailCode = ref("")
const password = ref("")
const accountRule = (value: string) => {
    if (value.length > 6) {
        return;
    }
    return $t('$vuetify.login_account_rule_title')
}
const passwordRule = (value: string) => {
    if (value.length > 5) {
        return;
    }
    return $t('$vuetify.login_password_rule_title')
}
const emailRule = (value: string) => {
    if (value.length > 3) {
        return;
    }
    return $t('$vuetify.login_email_code')
}

const emailRef = ref()
const emailCodeRef = ref()
const passwordRef = ref()
/** 獲取郵箱驗證碼 */
const getEmailCode = async (e: Function) => {
    if (!emailRef.value.validate()) {
        e();
        return;
    }
    try {
        await clientApi.userApi.sendCodeByEmail(email.value);
        ShowMessage($t('$vuetify.login_send_email_code'), 'success')
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}

/** 確認重置密碼 */
const forgetHandler = async (e: Function) => {
    if (!emailRef.value.validate() ||
        !emailCodeRef.value.validate() ||
        !passwordRef.value.validate()) {
        e();
        return;
    }
    try {
        await clientApi.userApi.resetPasswordByEmail({
            email: email.value,
            code: emailCode.value,
            password: password.value,
            repassword: password.value
        });
        closeHandler();
        EventInstance.emit(EventCustomType.LoginToLogin);
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}

/** 返回登錄 */
const clickToLogin = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToLogin);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LoginDialog, {
    title: _unref($t)('$vuetify.login_forget_title'),
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(CustomInput, {
            title: _unref($t)('$vuetify.login_account_title'),
            ref_key: "emailRef",
            ref: emailRef,
            modelValue: email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            placeholder: _unref($t)('$vuetify.login_account_title'),
            rules: accountRule
          }, null, 8, ["title", "modelValue", "placeholder"])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tk-h-1" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(CustomInput, {
            title: _unref($t)('$vuetify.login_email_code'),
            style: {"flex":"1 1 0"},
            ref_key: "emailCodeRef",
            ref: emailCodeRef,
            modelValue: emailCode.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((emailCode).value = $event)),
            placeholder: _unref($t)('$vuetify.login_email_code'),
            rules: emailRule
          }, {
            btn: _withCtx(() => [
              _createVNode(CustomButton, {
                class: "tk-m-l-5 tk-fs-12",
                onHandler: getEmailCode,
                title: _unref($t)('$vuetify.login_get_email_code')
              }, null, 8, ["title"])
            ]),
            _: 1
          }, 8, ["title", "modelValue", "placeholder"])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tk-h-1" }, null, -1)),
        _createElementVNode("div", null, [
          _createVNode(CustomInput, {
            title: _unref($t)('$vuetify.login_password_title'),
            ref_key: "passwordRef",
            ref: passwordRef,
            type: "password",
            rules: passwordRule,
            modelValue: password.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((password).value = $event)),
            placeholder: _unref($t)('$vuetify.login_password_title')
          }, null, 8, ["title", "modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "pointer",
            onClick: clickToLogin
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref($t)('$vuetify.login_back_login')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(CustomButton, {
            onHandler: forgetHandler,
            title: _unref($t)('$vuetify.login_forget_title')
          }, null, 8, ["title"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})