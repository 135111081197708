import { EventCustomType } from "@/event/type";
import { OnNotification, runNotification } from "../decorator/decorator";
import { WelcomeWallet } from "@/views/dialog/other";
import { walletClient } from "../wallet";
import { clientApi } from "@/api";
import { ShowMessage } from "@/views/dialog/tips";
import { ForgetViewDialog, LoginDialog, RegisterDialog } from "@/views/dialog/login";


class BusEvent {
    constructor() {
        runNotification(this);
    }

    @OnNotification(EventCustomType.LoginSuccess)
    loginSuccess() {
        WelcomeWallet();
    }

    @OnNotification(EventCustomType.UserSettingWallet)
    startSettingWallet() {
        console.log("設置錢包");
    }
    
    @OnNotification(EventCustomType.LoginToLogin)
    toLogin() {
        LoginDialog();
    }
    @OnNotification(EventCustomType.LoginToRegister)
    toRegister() {
        RegisterDialog();
    }
    @OnNotification(EventCustomType.LoginToForget)
    toForget() {
        ForgetViewDialog();
    }


    @OnNotification(EventCustomType.WalletSignMessage)
    async walletSignMessage() {
        try {
            const address = await walletClient.getDefaultAccount();
            const channel = "system";
            const timeStamp = new Date().getTime();
            const inviteCode = sessionStorage.getItem('INVITE_CODE') || '';
            const json = { account: address, channel, inviteCode, timeStamp, userRoleType: 'online' };
            const data = {
                account: json.account,
                channel: json.channel,
                inviter: inviteCode,
                timeStamp: json.timeStamp
            }
            const sign = await walletClient.signMessage(data);
            console.log("==>", sign);
            const result = await clientApi.userApi.walletAddressLogin({
                ...json,
                signature: sign
            })
            // console.log(result);
        } catch (error) {
            if (typeof error === "string") {
                ShowMessage(error);
                return;
            }
        }
    }

}
new BusEvent();