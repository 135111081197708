import { App, createApp, DirectiveBinding } from "vue";
import VueLazy from 'vue3-lazy'
import CustomLoading from "./Loading/VLoadCompont.vue";

const relative = 'position-re'
const handleAppend = (el: any) => {
    let style = getComputedStyle(el)
    console.log(style.position)
    if (!['absolute', 'relative', 'fixed'].includes(style.position)) {
        addClass(el, relative as any)
    }
    style = getComputedStyle(el)
    console.log(style.position)
    el.appendChild(el.instance.$el)
}
// 添加relative
const addClass = (el: any, className: string) => {
    if (!el.classList.contains(className)) {
        el.classList.add(className)
    }
}
// 删除relative
const removeClass = (el: any, className: string) => {
    el.classList.remove(className)
}
const handleRemove = (el: any) => {
    removeClass(el, relative as any)
    el.removeChild(el.instance.$el)
}
const loadDirective = {
    mounted(el: any, binding: DirectiveBinding) {
        const { value } = binding;
        const app = createApp(CustomLoading)
        const instance = app.mount(document.createElement('div'))
        el.instance = instance;
        console.log("value", value)
        if (value) {
            handleAppend(el);
        }
    },
    updated(el: any, binding: DirectiveBinding) {
        const { value, oldValue, arg } = binding
        if (value !== oldValue) {
            /* 更新标题 */
            if (arg) {
                el.instance.setTitle(arg)
            }
            // 是显示吗? 如果是就添加 : 如果不是就删除
            value ? handleAppend(el) : handleRemove(el)
        }
    }


}






export default {
    install(app: App) {
        app.directive("loading", loadDirective)
        app.use(VueLazy, {
            loading: require("@/assets/loading.png"),
        })
    }
}


