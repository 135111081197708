import { createVNode, Ref, ref, render, VNode } from "vue";
import MessageVue from "./message.vue";
import LoadingVue from "./loading.vue";

/** 提示與 */
let showVm: {
    vm: VNode
    timer: NodeJS.Timeout
}[] = [];
export const ShowMessage = (text: string, type: 'success' | "error" = 'error') => {
    const body = document.body;
    const close = (delVm?: VNode) => {
        if (showVm != null) {
            if (delVm && delVm.el) {
                body.removeChild(<Node>delVm.el);
                delVm = undefined;
            }
        }
    }
    while (showVm.length > 1) {
        const vm = showVm.shift();
        if (vm?.vm) {
            clearTimeout(vm.timer);
            close(vm.vm);
        }
    }
    for (let index = 0; index < showVm.length; index++) {
        const vm = showVm[index];
        vm!.vm.el!.style.top = `${10 + (showVm.length - index) * 8}%`
    }
    let vm: VNode = createVNode(MessageVue, {
        text,
        type
    })
    const timer = setTimeout(() => {
        const deletVm = showVm.shift();
        clearTimeout(timer);
        close(deletVm?.vm);
    }, 3000)
    showVm.push({
        vm,
        timer
    });
    const content = document.createElement("div");
    render(vm, content);
    body.appendChild(<Node>vm.el);
}


let loadingStatus = false;
export const showLoading = (text: string = 'loading') => {
    if (loadingStatus) return;
    loadingStatus = true;
    const body = document.body;
    let vm: VNode | null = createVNode(LoadingVue, {

    })
    const content = document.createElement("div");
    render(vm, content);
    body.appendChild(<Node>vm.el);
    vm.component?.exposed?.setText(text);
    const close = () => {
        loadingStatus = false;
        if (vm?.el) {
            body.removeChild(<Node>vm?.el);
            vm = null;
        }
    }
    return {
        setText: (text: string) => {
            vm?.component?.exposed?.setText(text);
        },
        close
    }
}