<template>
  <router-view/>
</template>


<script setup lang="ts">
import { onMounted } from 'vue';


onMounted(() => {
  console.log("loading end");
})

</script>

<style>

</style>
