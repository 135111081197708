import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

import { ref } from 'vue';
import CustomLoading from './CustomLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomButton',
  props: {
    title: { default: '' },
    color: { default: '#ffffff' },
    ground: { default: 'rgb(68, 199, 130)' }
  },
  emits: ['handler'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "d0fe37c8": (_ctx.color),
  "5f9abbee": (_ctx.ground)
}))

const emits = __emit




const loading = ref(false)
const clickHandler = () => {
    if (loading.value) return;
    loading.value = true;
    emits('handler', () => {
        loading.value = false;
    })
}


return (_ctx: any,_cache: any) => {
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([{
        opacity: loading.value ? 0.7 : 1
    }, {"color":"#141316"}]),
    class: "flex-row flex-center tk-br-8 btn pointer",
    onClick: clickHandler
  }, [
    (loading.value)
      ? (_openBlock(), _createBlock(CustomLoading, { key: 0 }))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
  ], 4)), [
    [_directive_ripple, !loading.value]
  ])
}
}

})