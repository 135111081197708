import { UserInfoInstance } from "@/plugins/user/user";
import { service } from "../service";
import { EncrptyPlugins } from "@/plugins/encrpty/rsa";

interface ResponseData {
    statusCode: number
    data: any,
    statusMsg: string
    error: string
}

export class BaseApi {
    constructor(private readonly host: string) {

    }

    public getWebPublicKey(): Promise<string> {
        return service.post<ResponseData>(`${this.host}/yluser/user/web/userWeb/getWebPublicKey`).then((res) => {
            if (res.statusCode === 200) {
                return res.data as string;
            }
            return Promise.reject({ code: res.statusCode, message: res.statusMsg });
        })
    }

    public async post(path: string, data?: any, params?: any, signal?: AbortSignal) {
        if (!EncrptyPlugins.isPublicKey()) {
            const result = await this.getWebPublicKey();
            EncrptyPlugins.setPublicKey(result);
        }
        return service.post<ResponseData>(`${this.host}${path}`,
            data ? EncrptyPlugins.dataEncryption(data) : data, {
            backToken: UserInfoInstance.currentToken
        }, params, {
            signal: signal
        }).then(res => {
            if (res.statusCode === 200) {
                return res.data;
            }
            return Promise.reject({ code: res.statusCode, message: res.statusMsg });
        });
    }


}