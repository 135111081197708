import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex-row flex-align-center flex-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "tk-p-l-10" }
const _hoisted_5 = {
  key: 1,
  class: "tk-fs-14 color-detail-color"
}

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomCheckBox',
  props: {
    modelValue: { type: Boolean },
    title: {},
    btnText: {}
  },
  emits: ['update:model-value', 'handler'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emits = __emit
const changeStatus = () => {
    emits('update:model-value', !props.modelValue);
}

const textList = computed(() => {
    if (!props.btnText) {
        return [props.title];
    }
    const list = [];
    let content = props.title;
    let index = content.indexOf(props.btnText);
    while (index != -1) {
        list.push(content.substring(0, index))
        list.push(props.btnText)
        content = content.substring(index + props.btnText.length, content.length);
        console.log("content==>", content);
        index = content.indexOf(props.btnText);
    }
    list.push(content);
    console.log(list);
    return list;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: changeStatus,
      class: "flex-row flex-center pointer"
    }, [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "tk-h-18 tk-w-18",
            src: require('@/assets/icon/check-icon.png'),
            alt: ""
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "tk-h-18 tk-w-18",
            src: require('@/assets/icon/check.png'),
            alt: ""
          }, null, 8, _hoisted_3))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textList.value, (item, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
          (item === _ctx.btnText)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('handler'))),
                class: "pointer color-title-btn tk-fs-15"
              }, _toDisplayString(_ctx.btnText), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item), 1))
        ], 64))
      }), 128))
    ])
  ]))
}
}

})