import JsEncrypt from 'encryptlong'

const encrypt = new JsEncrypt()
let isSetPublicKey = false;

export const EncrptyPlugins = {
    /** 是否配置公钥 */
    isPublicKey: () => {
        return isSetPublicKey;
    },
    /** 取消公钥配置 */
    CanclePublicKey: () => {
        isSetPublicKey = false;
    },
    /** 设置公钥 */
    setPublicKey: (publicKey: string) => {
        isSetPublicKey = true;
        sessionStorage.setItem("__PUBLIC_KEY__", publicKey);
        encrypt.setPublicKey(publicKey);
    },
    /** 加密数据 */
    dataEncryption: (obj: object) => {
        if (!EncrptyPlugins.isPublicKey()) return obj;
        return encrypt.encryptLong(JSON.stringify(obj))
    }
}
const data = sessionStorage.getItem("__PUBLIC_KEY__")
if (data) {
    EncrptyPlugins.setPublicKey(data);
}
