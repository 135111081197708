<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue';

const emits = defineEmits(['update:model-value'])
const props = withDefaults(defineProps<{
    modelValue?: string
    title?: string
    type?: 'text' | 'password'
    placeholder?: string
    rules?: (value: string) => string | undefined
    focusColor?: string
    color?: string
    placeholderColor?: string
    fontSize?: string
    canHide?: boolean,
    showInput?: boolean
}>(), {
    canHide: false,
    title: '',
    type: 'text',
    placeholder: '',
    modelValue: '',
    focusColor: 'rgb(85, 112, 134)',
    color: '#ffffff',
    placeholderColor: '#999999',
    fontSize: '14px',
    showInput: true

})
const inputType = ref(props.type);
const changeValue = (e: Event) => {
    emits('update:model-value', (e.target as HTMLInputElement).value)
}
/// 0 normal 1 focus  2 error
const status = ref(0)
const clickStatus = ref(false)
const error = ref("")

const enterFocus = (sta: boolean) => {
    if (!sta && error.value) return;
    if (sta) {
        clickStatus.value = true;
    }
    status.value = 1;
}
const exitFocus = (sta: boolean) => {
    if (sta && props.rules) {
        error.value = props.rules(props.modelValue) ?? "";
        if (error.value) {
            status.value = 2;
            return;
        }
        if (status.value == 2) {
            status.value = 0;
        }
    }
    if (status.value != 2) {
        if (sta) {
            clickStatus.value = false;
            status.value = 0
        }
        if (clickStatus.value) {
            return;
        }
        clickStatus.value = false;
        status.value = 0
    }
}

defineExpose({
    validate: () => {
        exitFocus(true);
        return status.value === 0;
    }
})

const changeInputType = () => {
    inputType.value = inputType.value == 'password' ? 'text' : 'password'
}

const showInputType = ref(props.showInput)
const changeShowInput = () => {
    showInputType.value = !showInputType.value;
}

</script>


<template>
    <div class="position-re " :class="rules ? 'tk-p-b-25' : 'tk-p-b-15'">
        <div class="tk-fs-14 tk-h-20 flex-row flex-align-center" v-if="title" style="color: #808191;">
            <div>{{ title }}</div>
            <div v-if="canHide" class="h-1 tk-p-v-3 flex-row flex-center pointer" @click="changeShowInput"
                style="transition: all 0.25s;" :style="{
                    transform: `rotateZ(${showInputType ? 90 : 0}deg)`
                }">
                <img class="h-1 tk-w-34" :src="require('@/assets/icon/arrow.png')" alt="">
            </div>
        </div>
        <div class="flex-row flex-align-center" style="transition: all 0.25s;"
            :class="showInputType ? 'active' : 'inactive'">
            <div class="input-div changeShowInput  animate__animated flex-row flex-align-center tk-m-t-12"
                style="flex:1 1 0" :style="{
                    borderColor: status == 0 ? 'rgb(47, 70, 83)' : status === 1 ? focusColor : 'rgb(237,65,100)'
                }">
                <input @change="changeValue" @mouseenter="enterFocus(false)" @mouseleave="exitFocus(false)"
                    @focusin="enterFocus(true)" @focusout="exitFocus(true)" :type="inputType"
                    :placeholder="placeholder">
                <div style="margin-left: auto" class="pointer h-1 tk-p-v-5" v-if="type === 'password'"
                    @click="changeInputType">
                    <img class="h-1" v-if="inputType == 'password'" :src="require('@/assets/icon/eye.png')" alt="">
                    <img class="h-1" v-else :src="require('@/assets/icon/eye.png')" alt="">
                </div>
            </div>
            <div class="h-1 tk-p-t-12 pointer">
                <slot name="btn"></slot>
            </div>
        </div>
        <div class="position-ab" style="bottom: 0;" v-if="error">
            <span class="tk-fs-12" style="color: rgb(237, 65, 100);">{{ error }}</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.active {
    height: 40px;
    opacity: 1;
}

.inactive {
    height: 0;
    opacity: 0;
}

.input-div {
    border-width: 2px;
    // height: 40px;
    height: 100%;
    border-style: solid;
    border-color: rgb(47, 70, 83);
    border-radius: 6px;
    padding: 2px 10px;

    input {
        width: 100%;
        height: 100%;
        outline: none;
        color: v-bind(color);
        font-size: v-bind(fontSize);

        &::placeholder {
            color: v-bind(placeholderColor);
        }
    }
}
</style>