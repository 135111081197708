import { EventCustomType } from "@/event/type";
import { OnNotification, runNotification } from "../decorator/decorator";
import { EventInstance } from "@/event";




class UserInfo {

    /** 登錄類型 */
    loginType: UserType = 'email'
    /** 角色類型 */
    roleType: RoleType = 'normal';
    private _token: string | undefined;

    walletAddress: string | undefined;

    constructor() {
        runNotification(this);
    }

    /** token */
    get currentToken(): string {
        return this._token ?? sessionStorage.getItem('__V_Token__') ?? ""
    }
    get isToken(): boolean {
        return !!this.currentToken
    }

    @OnNotification(EventCustomType.AccountChangeWallet)
    walletAccountChange(account: string[]) {
        console.log("walletAccountChange", account);
        if (account.length == 0) {
            this.walletAddress = undefined;
            EventInstance.emit(EventCustomType.DisconnectWallet);
            return;
        }
        if (account.length > 0) {
            this.walletAddress = account[0];
        }
        if (!this.currentToken) {
            EventInstance.emit(EventCustomType.WalletSignMessage);
        }
    }

    /** 登錄成功 */
    @OnNotification(EventCustomType.LoginSuccess)
    loginSuccess(token: string) {
        this._token = token
        sessionStorage.setItem("__V_Token__", token)
        EventInstance.emit(EventCustomType.UpdateTokenInfo);
    }

}

export const UserInfoInstance = new UserInfo();