import { EventInstance } from "@/event";

const ReflectMetaMap: Map<Object, Map<any, any>> = new Map();
var functionPrototype = Object.getPrototypeOf(Function);
const isObject = (x: any) => typeof x === "object" ? x !== null : typeof x === "function";
function OrdinaryGetPrototypeOf(O: any) {
    var proto = Object.getPrototypeOf(O);
    if (typeof O !== "function" || O === functionPrototype)
        return proto;
    if (proto !== functionPrototype)
        return proto;
    var prototype = O.prototype;
    var prototypeProto = prototype && Object.getPrototypeOf(prototype);
    if (prototypeProto == null || prototypeProto === Object.prototype)
        return proto;
    var constructor = prototypeProto.constructor;
    if (typeof constructor !== "function")
        return proto;
    if (constructor === O)
        return proto;
    return constructor;
}
function defineMetadata(metadataKey: any, metadataValue: any, target: Object) {
    if (!isObject(target)) {
        return null;
    }
    let targetMap = ReflectMetaMap.get(target);
    if (!targetMap) {
        targetMap = new Map();
        ReflectMetaMap.set(target, targetMap);
    }
    targetMap.set(metadataKey, metadataValue);
}
function getMetadata(metadataKey: any, target: Object) {
    if (!isObject(target)) {
        return undefined;
    }
    let targetMap = ReflectMetaMap.get(target);
    if (!targetMap) {
        const targetKey = OrdinaryGetPrototypeOf(target);
        targetMap = ReflectMetaMap.get(targetKey);
    }
    if (!targetMap) {
        return null;
    }
    return targetMap.get(metadataKey);
}
export interface WorkerType {
    postMessage: (data: { cmd: string, data?: any }) => void,
    onmessage: (e: { data: { cmd: string, data: any } }) => void,
    terminate: () => void
}
export function OnMessage(cmd: string): any {
    return (target: any, propertyKey: string | symbol) => {
        if (typeof (target[propertyKey]) !== "function") {
            throw new Error(`Decorated propery "${propertyKey.toString()}" in class "${target.constructor.name}" must be a function.`);
        }
        defineMetadata(cmd, propertyKey, target)
    };
}
/** worker */
export function runMessage(target: Object, worker: WorkerType) {
    worker.onmessage = function (e) {
        const { cmd, data } = e.data;
        const propertyKey = getMetadata(cmd, target)
        if (propertyKey) {
            //@ts-ignore
            target[propertyKey] && target[propertyKey](data)
        }
    }
}
export function OnNotification(name: string): any {
    return (target: any, propertyKey: string) => {
        if (typeof (target[propertyKey]) !== "function") {
            throw new Error(`Decorated propery "${propertyKey.toString()}" in class "${target.constructor.name}" must be a function.`);
        }
        let list = getMetadata("notification", target)
        if (!list) {
            list = []
        }
        list.push({ name, propertyKey })
        defineMetadata("notification", list, target)
    }
}
/** notification */
export function runNotification(target: Object) {
    let list = getMetadata("notification", target)
    // console.log('===>', list);
    if (list && Array.isArray(list) && list.length > 0) {
        list.forEach(({ name, propertyKey }) => {
            // @ts-ignore
            EventInstance.on(name, target[propertyKey].bind(target))
        })
    }
}
export function stopNotfication(target: Object) {
    let list = getMetadata("notification", target)
    if (list && Array.isArray(list) && list.length > 0) {
        list.forEach(({ name, propertyKey }) => {
            // @ts-ignore
            EventInstance.off(name, target[propertyKey].bind(target))
        })
    }
}