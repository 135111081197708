
import { createVNode, render, VNode } from "vue";
import welcomeVue from "./welcome.vue";

export const WelcomeWallet = () => {
    const body = document.body;
    let vm: VNode | null = createVNode(welcomeVue, {
        onClose() {
            if (vm?.el) {
                body.removeChild(<Node>vm.el);
            }
            vm = null;
        }
    });
    const div = document.createElement("div");
    render(vm, div);
    body.appendChild(<Node>vm.el);
}