<script setup lang="ts">
import { ref } from 'vue';
import CustomLoading from './CustomLoading.vue';

const emits = defineEmits(['handler'])


withDefaults(defineProps<{
    title?: string
    color?: string
    ground?: string
}>(), {
    title: '',
    color: '#ffffff',
    ground: 'rgb(68, 199, 130)'
})

const loading = ref(false)
const clickHandler = () => {
    if (loading.value) return;
    loading.value = true;
    emits('handler', () => {
        loading.value = false;
    })
}

</script>

<template>
    <div :style="{
        opacity: loading ? 0.7 : 1
    }" v-ripple="!loading"
        style="color: #141316;"
    class="flex-row flex-center tk-br-8 btn pointer" @click="clickHandler">
        <CustomLoading v-if="loading"></CustomLoading>
        {{ title }}
    </div>
</template>


<style lang="scss" scoped>
.btn {
    color: v-bind(color);
    padding: 6px 10px;
    background-color: v-bind(ground);
}
</style>