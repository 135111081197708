import { JsonRpcProvider } from "ethers";
import { XBaseWallet } from "x-wallet";
import MetaMaskWalletType from "./type/metamaskType";
import WalletConnectWalletType from "./type/walletConnect";
import { ERCConfig } from "@/env";
import DefaultWalletType from "./type/defaultType";
import { ShowMessage } from "@/views/dialog/tips";
import { EventInstance } from "@/event";
import { EventCustomType } from "@/event/type";


const metaType = new MetaMaskWalletType();
const walletConnect = new WalletConnectWalletType();

class WalletClient extends XBaseWallet {

    constructor() {
        super();
        console.log(this);
        this.initMethod();
        this.reloadAccount();
        console.log(this.getWallet(), this.getWalletName());
    }

    reloadAccount() {
        const self = this;
        console.log(super.currentName);
        if (super.currentName == 'metamask') {
            metaType.getProvider().then(
                (provide) => {
                    if (provide) {
                        metaType.getDefaultAccount().then(async (res) => {
                            if (!!res) {
                                const status = await super.connectWallet("metamask");
                                if (status) {
                                    EventInstance.emit(EventCustomType.AccountChangeWallet, [res]);
                                }
                            } else {
                                setTimeout(() => {
                                    self.reloadAccount();
                                }, 5000);
                            }
                        });
                    } else {

                    }
                },
                (error) => {
                    console.log("error==>", error);
                }
            );
        }
        if (super.currentName == 'walletConnect') {
            walletConnect.getDefaultAccount().then(async (res) => {
                if (!!res) {
                    const status = await super.connectWallet("walletConnect");
                    if (status) {
                        EventInstance.emit(EventCustomType.AccountChangeWallet, [res]);
                    }
                } else {
                    setTimeout(() => {
                        self.reloadAccount();
                    }, 5000);
                }
            });
        }
    }

    initBaseProvider() {
        const keys = Object.keys(ERCConfig.multiChain ?? {});
        const map: { [key: string]: JsonRpcProvider } = {};
        keys.forEach((key) => {
            map[key] = this.createBaseProvider(ERCConfig.multiChain[key].host);
        });
        return map;
    };
    initProvider() {
        return {
            base: new DefaultWalletType(),
            walletConnect: walletConnect,
            metamask: metaType,
        };
    };

    initMethod() {

    };

    async connectWallet(): Promise<boolean> {
        console.trace("connectWallet");
        try {
            const provider = await metaType.getProvider();
            if (provider) {
                return super.connectWallet("metamask");
            }
            return super.connectWallet("walletConnect");
        } catch (error: any) {
            ShowMessage(error.toString());
        }
        return false;
    }

    async connectWalletByType(type = 2) {
        console.trace("connect wallet");
        try {
            const provider = await metaType.getProvider();
            if (provider && type === 2) {
                console.log('metamask 连接')
                return super.connectWallet("metamask");
            }
            return super.connectWallet("walletConnect");
        } catch (error) {
            ShowMessage((error as string).toString());
        }
    }

}
export const walletClient = new WalletClient();