import { ERCConfig } from "@/env";
import { EventInstance } from "@/event";
import { EventCustomType } from "@/event/type";
import { XMetaMaskType } from "x-wallet";


export default class MetaMaskWalletType extends XMetaMaskType {

    getChainsInfo = (chainId: string) => {
        if (Reflect.has(ERCConfig.multiChain, chainId)) {
            const config = ERCConfig.multiChain[chainId];
            return {
                chainId,
                token: config.tokenName,
                explorer: config.explorer,
                rpcUrl: config.host,
                chainName: config.name,
            }
        }
        const config = ERCConfig.multiChain[ERCConfig.defaultChain];
        return {
            chainId: ERCConfig.defaultChain,
            token: config.tokenName,
            explorer: config.explorer,
            rpcUrl: config.host,
            chainName: config.name,
        };
    }

    getRightChainId() {
        let defaultChainId = ERCConfig.defaultChain;
        // @ts-ignore
        if (window['ethereum'].networkVersion != null) {
            // @ts-ignore
            defaultChainId = window['ethereum'].networkVersion
        }
        const config = this.getChainsInfo(defaultChainId);
        return config.chainId;
    }

    getRightChainName() {
        const config = this.getChainsInfo(ERCConfig.defaultChain)
        return config.chainName
    }

    getRightChainHost() {
        const config = this.getChainsInfo(ERCConfig.defaultChain)
        return config.rpcUrl
    }

    getRightChainExplorer() {
        const config = this.getChainsInfo(ERCConfig.defaultChain)
        return config.explorer
    }

    getRightTokenName() {
        const config = this.getChainsInfo(ERCConfig.defaultChain)
        return config.token
    }

    getProvider() {
        return new Promise((resolve) => {
            // @ts-ignore
            if (window && window['ethereum']) {
                // @ts-ignore
                resolve(window.ethereum)
                return;
            }
            setTimeout(() => {
                // @ts-ignore
                if (window && window['ethereum']) {
                    // @ts-ignore
                    resolve(window.ethereum)
                    return;
                }
                return resolve(null);
            }, 2000);
        })
    }

    listenAccountChanged(accounts: string[]) {
        // console.log("metamask==>", accounts);
        EventInstance.emit(EventCustomType.AccountChangeWallet, accounts);
    }

    listenChainIdChanged(chainId: string) {

    }

}