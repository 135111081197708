

export const ERCConfig: ERCConfigInterface = {
    defaultChain: '97',
    chainHost: '',
    multiChain: {
        '56': {
            name: 'BSC',
            explorer: '',
            tokenName: 'BSC',
            host: 'bsc-dataseed.binance.org'
        }
    }
}