<template>
    <LoginDialog :title="$t('$vuetify.btn_login')" @close="closeHandler">
        <div class="tk-p-h-30 tk-p-t-50">
            <div class="">
                <CustomInput :title="$t('$vuetify.login_account_title')" ref="emailRef" v-model="email"
                    :placeholder="$t('$vuetify.login_account_title')" :rules="accountRule">
                </CustomInput>
            </div>
            <div class="tk-h-5"></div>
            <div>
                <CustomInput :title="$t('$vuetify.login_password_title')" ref="passwordRef" type="password"
                    :rules="passwordRule" v-model="password" :placeholder="$t('$vuetify.login_password_title')">
                </CustomInput>
            </div>
            <div class="w-1 flex-row">
                <div></div>
                <div @click="forgetHandler" class="pointer" style="margin-left: auto;">
                    <span class="tk-fs-14" style="color: rgb(128, 129, 149);">{{ $t('$vuetify.login_forget_title')
                        }}?</span>
                </div>
            </div>
            <div class="tk-m-t-30">
                <CustomButton @handler="loginHandler" :title="$t('$vuetify.btn_login')"></CustomButton>
            </div>
            <div class="w-1 flex-row tk-p-t-20 flex-align-center">
                <span class="tk-fs-14">{{ $t('$vuetify.login_create_new_account') }}</span>
                <div class="pointer" @click="clickToRegister">
                    <span class="tk-fs-14 color-title-btn tk-p-l-10">{{ $t('$vuetify.login_create_account') }}</span>
                </div>
            </div>
        </div>
        <div style="margin-top: auto;" class="tk-p-b-10">
            <CustomGuide :title="$t('$vuetify.login_read_login_title')"></CustomGuide>
            <div class="flex-row flex-center tk-p-v-10" style="gap: 0.5rem;">
                <div class="pointer" data-type="icon" id="g_id_onload"
                    data-client_id="8blq1p68iu4r9bacf8nuv3es3nmsjekm.apps.googleusercontent.com "
                    data-callback="handleToken" data-context="signin" data-your_own_param_1_to_login="any_value"
                    data-your_own_param_2_to_login="any_value">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
                <div class="pointer">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
                <div class="pointer" @click="toConnectMetaMask(2)">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
                <div class="pointer" @click="toConnectMetaMask(1)">
                    <img :src="require('@/assets/icon/Vector.png')" alt="">
                </div>
            </div>
        </div>
    </LoginDialog>
</template>


<script setup lang="ts">
import CustomButton from '@/components/input/CustomButton.vue';
import CustomInput from '@/components/input/CustomInput.vue';
import CustomGuide from '@/components/input/CustomGuide.vue';

import { $t } from '@/locale';
import { onMounted, ref } from 'vue';
import { clientApi } from '@/api';
import { showLoading, ShowMessage } from '../tips';
import { walletClient } from '@/plugins/wallet';
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';
import LoginDialog from './loginDialog.vue';
const emits = defineEmits(['close'])
defineProps<{

}>()
const closeHandler = () => {
    emits('close')
}

const email = ref("")
const password = ref("")
const accountRule = (value: string) => {
    if (value.length > 6) {
        return;
    }
    return $t('$vuetify.login_account_rule_title')
}
const passwordRule = (value: string) => {
    if (value.length > 5) {
        return;
    }
    return $t('$vuetify.login_password_rule_title')
}

const emailRef = ref()
const passwordRef = ref()
const loginHandler = async (e: Function) => {
    if (!emailRef.value.validate() && !passwordRef.value.validate()) {
        e();
        return;
    }
    try {
        const token = await clientApi.userApi.webLogin<string>(email.value, password.value);
        closeHandler();
        EventInstance.emit(EventCustomType.LoginSuccess, token);
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}


/** 跳轉註冊 */
const clickToRegister = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToRegister);
}
/** 忘記密碼 */
const forgetHandler = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToForget);
}
/** connect metamask */
const toConnectMetaMask = async (type: number) => {
    console.log(type);
    const loading = showLoading('connecting');
    try {
        const status = await walletClient.connectWalletByType(type);
        if (!status) {
            ShowMessage("Disconnect Error");
        }
    } catch (error) {
        console.log(error);

        if (typeof error === 'string') {
            ShowMessage(error);
        }
        console.log(error);
    }
    loading?.close();
}

//@ts-ignore
const handleCredentialResponse = function () {
    console.log(arguments);
}

onMounted(() => {
    // @ts-ignore
    google.accounts.id.initialize({
        client_id: '297643597449-8blq1p68iu4r9bacf8nuv3es3nmsjekm.apps.googleusercontent.com',
        cancel_on_tap_outside: false,
        callback: (e: any) => {
            let a = e.credential.split(".");
            const o = JSON.parse(decodeURIComponent(encodeURI(window.atob(a[1].replace(/-/g, "+").replace(/_/g, "/")))));
            console.log("idToken==>", e.credential);
            clientApi.userApi.gmailLogin({
                idToken: e.credential,
            }).then(res => {
                EventInstance.emit(EventCustomType.LoginSuccess, res);
            })
        }
    });
    // const parent = document.getElementById('google_btn');
    // // @ts-ignore
    // google.accounts.id.renderButton(parent, { theme: "filled_blue" });
    // @ts-ignore
    google.accounts.id.prompt();
})


</script>


<style lang="scss" scoped>
.content {
    height: 716px;
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
}
</style>