<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    modelValue: boolean
    title: string
    btnText?: string
}>()
const emits = defineEmits(['update:model-value', 'handler'])
const changeStatus = () => {
    emits('update:model-value', !props.modelValue);
}

const textList = computed(() => {
    if (!props.btnText) {
        return [props.title];
    }
    const list = [];
    let content = props.title;
    let index = content.indexOf(props.btnText);
    while (index != -1) {
        list.push(content.substring(0, index))
        list.push(props.btnText)
        content = content.substring(index + props.btnText.length, content.length);
        console.log("content==>", content);
        index = content.indexOf(props.btnText);
    }
    list.push(content);
    console.log(list);
    return list;
})

</script>

<template>
    <div class="flex-row flex-align-center flex-wrap">
        <div @click="changeStatus" class="flex-row flex-center pointer">
            <img v-if="modelValue" class="tk-h-18 tk-w-18" :src="require('@/assets/icon/check-icon.png')" alt="">
            <img v-else class="tk-h-18 tk-w-18" :src="require('@/assets/icon/check.png')" alt="">
        </div>
        <div class="tk-p-l-10">
            <template v-for="(item, idx) in textList" :key="idx">
                <span v-if="item === btnText" @click="emits('handler')"
                 class="pointer color-title-btn tk-fs-15">{{
                    btnText }}</span>
                <span v-else class="tk-fs-14 color-detail-color">{{ item }}</span>
            </template>

        </div>
    </div>
</template>

<style lang="" scoped>

</style>