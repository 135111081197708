import { UserApi } from "./module/userApi";



const host = process.env.NODE_ENV === "production" ? "" : "http://54.193.89.154"

export const clientApi = {

    userApi: new UserApi(host)

}








