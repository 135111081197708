import Axios, { GenericAbortSignal } from 'axios'

const axios = Axios.create({
    baseURL: '',
    headers: {
        'Content-Type': 'application/json'
    }
})

function parseResponse(data: any) {
    let dataString = data
    try {
        return JSON.parse(dataString)
    } catch (e) {
        return dataString
    }
}

axios.interceptors.request.use((config: any) => {
    config.transformResponse = [parseResponse]
    return config
})

axios.interceptors.response.use((res: any) => {
    if (res.status === 200 || res.code === 200) {
        return res.data
    }
    return Promise.reject(new Error(res.statusText))
})

export const service = {
    post<T>(url: string, data?: any, headers?: { [key in string]: any }, params?: { [key in string]: any }, options?: {
        signal?: GenericAbortSignal
        responseType?: ResponseType,
    }): Promise<T> {
        return axios.post(url, data, {
            headers,
            params,
            signal: options?.signal,
            /// @ts-ignore
            responseType: options?.responseType ?? 'json',
        })
    },
    get<T>(url: string, headers?: { [key in string]: any }, options?: {
        signal?: GenericAbortSignal
        responseType?: ResponseType,
    }): Promise<T> {
        return axios.get(url, {
            /// @ts-ignore
            responseType: options?.responseType ?? 'json',
            signal: options?.signal,
            headers,
        })
    }
}