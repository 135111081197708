import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-row flex-align-center" }
const _hoisted_2 = {
  class: "tk-p-h-30 tk-fs-14",
  style: {"color":"#999999cc"}
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomGuide',
  props: {
    title: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "line" }, null, -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "line" }, null, -1))
  ]))
}
}

})