import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tk-p-h-30 tk-p-t-50" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "w-1 flex-row" }
const _hoisted_4 = {
  class: "tk-fs-14",
  style: {"color":"rgb(128, 129, 149)"}
}
const _hoisted_5 = { class: "tk-m-t-30" }
const _hoisted_6 = { class: "w-1 flex-row tk-p-t-20 flex-align-center" }
const _hoisted_7 = { class: "tk-fs-14" }
const _hoisted_8 = { class: "tk-fs-14 color-title-btn tk-p-l-10" }
const _hoisted_9 = {
  style: {"margin-top":"auto"},
  class: "tk-p-b-10"
}
const _hoisted_10 = {
  class: "flex-row flex-center tk-p-v-10",
  style: {"gap":"0.5rem"}
}
const _hoisted_11 = {
  class: "pointer",
  "data-type": "icon",
  id: "g_id_onload",
  "data-client_id": "8blq1p68iu4r9bacf8nuv3es3nmsjekm.apps.googleusercontent.com ",
  "data-callback": "handleToken",
  "data-context": "signin",
  "data-your_own_param_1_to_login": "any_value",
  "data-your_own_param_2_to_login": "any_value"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "pointer" }
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]

import CustomButton from '@/components/input/CustomButton.vue';
import CustomInput from '@/components/input/CustomInput.vue';
import CustomGuide from '@/components/input/CustomGuide.vue';

import { $t } from '@/locale';
import { onMounted, ref } from 'vue';
import { clientApi } from '@/api';
import { showLoading, ShowMessage } from '../tips';
import { walletClient } from '@/plugins/wallet';
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';
import LoginDialog from './loginDialog.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'login',
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const emits = __emit

const closeHandler = () => {
    emits('close')
}

const email = ref("")
const password = ref("")
const accountRule = (value: string) => {
    if (value.length > 6) {
        return;
    }
    return $t('$vuetify.login_account_rule_title')
}
const passwordRule = (value: string) => {
    if (value.length > 5) {
        return;
    }
    return $t('$vuetify.login_password_rule_title')
}

const emailRef = ref()
const passwordRef = ref()
const loginHandler = async (e: Function) => {
    if (!emailRef.value.validate() && !passwordRef.value.validate()) {
        e();
        return;
    }
    try {
        const token = await clientApi.userApi.webLogin<string>(email.value, password.value);
        closeHandler();
        EventInstance.emit(EventCustomType.LoginSuccess, token);
    } catch (error: any) {
        ShowMessage(error.message);
    }
    e();
}


/** 跳轉註冊 */
const clickToRegister = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToRegister);
}
/** 忘記密碼 */
const forgetHandler = () => {
    closeHandler();
    EventInstance.emit(EventCustomType.LoginToForget);
}
/** connect metamask */
const toConnectMetaMask = async (type: number) => {
    console.log(type);
    const loading = showLoading('connecting');
    try {
        const status = await walletClient.connectWalletByType(type);
        if (!status) {
            ShowMessage("Disconnect Error");
        }
    } catch (error) {
        console.log(error);

        if (typeof error === 'string') {
            ShowMessage(error);
        }
        console.log(error);
    }
    loading?.close();
}

//@ts-ignore
const handleCredentialResponse = function () {
    console.log(arguments);
}

onMounted(() => {
    // @ts-ignore
    google.accounts.id.initialize({
        client_id: '297643597449-8blq1p68iu4r9bacf8nuv3es3nmsjekm.apps.googleusercontent.com',
        cancel_on_tap_outside: false,
        callback: (e: any) => {
            let a = e.credential.split(".");
            const o = JSON.parse(decodeURIComponent(encodeURI(window.atob(a[1].replace(/-/g, "+").replace(/_/g, "/")))));
            console.log("idToken==>", e.credential);
            clientApi.userApi.gmailLogin({
                idToken: e.credential,
            }).then(res => {
                EventInstance.emit(EventCustomType.LoginSuccess, res);
            })
        }
    });
    // const parent = document.getElementById('google_btn');
    // // @ts-ignore
    // google.accounts.id.renderButton(parent, { theme: "filled_blue" });
    // @ts-ignore
    google.accounts.id.prompt();
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LoginDialog, {
    title: _unref($t)('$vuetify.btn_login'),
    onClose: closeHandler
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(CustomInput, {
            title: _unref($t)('$vuetify.login_account_title'),
            ref_key: "emailRef",
            ref: emailRef,
            modelValue: email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            placeholder: _unref($t)('$vuetify.login_account_title'),
            rules: accountRule
          }, null, 8, ["title", "modelValue", "placeholder"])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tk-h-5" }, null, -1)),
        _createElementVNode("div", null, [
          _createVNode(CustomInput, {
            title: _unref($t)('$vuetify.login_password_title'),
            ref_key: "passwordRef",
            ref: passwordRef,
            type: "password",
            rules: passwordRule,
            modelValue: password.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            placeholder: _unref($t)('$vuetify.login_password_title')
          }, null, 8, ["title", "modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1)),
          _createElementVNode("div", {
            onClick: forgetHandler,
            class: "pointer",
            style: {"margin-left":"auto"}
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref($t)('$vuetify.login_forget_title')) + "?", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(CustomButton, {
            onHandler: loginHandler,
            title: _unref($t)('$vuetify.btn_login')
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref($t)('$vuetify.login_create_new_account')), 1),
          _createElementVNode("div", {
            class: "pointer",
            onClick: clickToRegister
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref($t)('$vuetify.login_create_account')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(CustomGuide, {
          title: _unref($t)('$vuetify.login_read_login_title')
        }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_12)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_14)
          ]),
          _createElementVNode("div", {
            class: "pointer",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (toConnectMetaMask(2)))
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_15)
          ]),
          _createElementVNode("div", {
            class: "pointer",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (toConnectMetaMask(1)))
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icon/Vector.png'),
              alt: ""
            }, null, 8, _hoisted_16)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})