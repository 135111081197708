import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex-row flex-center position-ab",
  style: {"inset":"0","z-index":"1000","background-color":"#00000088"}
}

import CustomLoading from '@/components/input/CustomLoading.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'VLoadCompont',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ['update:model-value'],
  setup(__props: any, { emit: __emit }) {

const emits = __emit



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CustomLoading, { style: {"margin":"auto"} })
  ]))
}
}

})