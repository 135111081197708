<template>
    <div class="mask-content flex-row flex-center animate__animated animate__fadeIn">
        <div style="overflow: hidden;"
            class="welcome-content flex-column animate__animated color-header tk-br-6 animate__fadeInUp animate__faster">
            <div class="w-1 tk-h-260">
                <img class="w-1 h-1" style="object-fit: cover;"
                    src="https://stake.com/_app/immutable/assets/onboarding-desktop.CldPt7e9.jpg" alt="">
            </div>
            <div class="tk-p-h-16 tk-p-t-32 tk-p-b-16">
                <div class="tk-fs-24" style="font-weight: 800;">
                    歡迎使用Ti安城
                </div>
                <div class="tk-fs-14 tk-m-t-8 color-title">
                    我们知道您已迫不及待地想开始，所以让我们快速设置您的钱包。 这将允许您无缝地存入和提取资金。
                </div>
            </div>
            <div class="flex-row flex-align-center flex-just-between tk-p-h-16 tk-p-b-20">
                <div @click="clickAfter" class="flex-row color-default flex-center tk-h-40 tk-br-6" v-ripple
                    style="width: 50%;">先跳過</div>
                <div class="tk-w-10"></div>
                <div @click="clickHandlerConfirm" class="flex-row flex-center tk-h-40 color-primary tk-br-6" v-ripple
                    style="width: 50%;">設置錢包</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';


const emits = defineEmits(['close'])
/// 選擇設置
const clickHandlerConfirm = () => {
    EventInstance.emit(EventCustomType.UserSettingWallet);
    emits('close')
}
/// 跳過設置
const clickAfter = () => {
    emits('close')
}

</script>

<style lang="scss" scoped>
.welcome-content {
    max-width: 500px;
    width: 100%;
    margin: 0 20px;
    // height: 100px;
}
</style>