import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mask-content flex-row flex-center animate__animated animate__fadeIn" }
const _hoisted_2 = {
  style: {"overflow":"hidden"},
  class: "welcome-content flex-column animate__animated color-header tk-br-6 animate__fadeInUp animate__faster"
}
const _hoisted_3 = { class: "flex-row flex-align-center flex-just-between tk-p-h-16 tk-p-b-20" }

import { EventInstance } from '@/event';
import { EventCustomType } from '@/event/type';



export default /*@__PURE__*/_defineComponent({
  __name: 'welcome',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emits = __emit
/// 選擇設置
const clickHandlerConfirm = () => {
    EventInstance.emit(EventCustomType.UserSettingWallet);
    emits('close')
}
/// 跳過設置
const clickAfter = () => {
    emits('close')
}


return (_ctx: any,_cache: any) => {
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"w-1 tk-h-260\" data-v-15f65b77><img class=\"w-1 h-1\" style=\"object-fit:cover;\" src=\"https://stake.com/_app/immutable/assets/onboarding-desktop.CldPt7e9.jpg\" alt=\"\" data-v-15f65b77></div><div class=\"tk-p-h-16 tk-p-t-32 tk-p-b-16\" data-v-15f65b77><div class=\"tk-fs-24\" style=\"font-weight:800;\" data-v-15f65b77> 歡迎使用Ti安城 </div><div class=\"tk-fs-14 tk-m-t-8 color-title\" data-v-15f65b77> 我们知道您已迫不及待地想开始，所以让我们快速设置您的钱包。 这将允许您无缝地存入和提取资金。 </div></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          onClick: clickAfter,
          class: "flex-row color-default flex-center tk-h-40 tk-br-6",
          style: {"width":"50%"}
        }, _cache[0] || (_cache[0] = [
          _createTextVNode("先跳過")
        ]))), [
          [_directive_ripple]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "tk-w-10" }, null, -1)),
        _withDirectives((_openBlock(), _createElementBlock("div", {
          onClick: clickHandlerConfirm,
          class: "flex-row flex-center tk-h-40 color-primary tk-br-6",
          style: {"width":"50%"}
        }, _cache[1] || (_cache[1] = [
          _createTextVNode("設置錢包")
        ]))), [
          [_directive_ripple]
        ])
      ])
    ])
  ]))
}
}

})