import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mask-content animate__animated animate__fadeIn" }
const _hoisted_2 = { class: "animate__animated flex-column animate__fadeInUp position-re animate__faster max-dialog-width color-menu content" }
const _hoisted_3 = { class: "position-re flex-row flex-center tk-h-60 tk-p-h-20" }
const _hoisted_4 = { class: "tk-fs-40 t position-ab" }
const _hoisted_5 = ["src"]


export default /*@__PURE__*/_defineComponent({
  __name: 'loginDialog',
  props: {
    title: {}
  },
  emits: ['close'],
  setup(__props: any) {







return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", {
          class: "position-ab",
          style: {"right":"0.5rem"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createElementVNode("img", {
            class: "tk-w-34 pointer",
            src: require('@/assets/icon/X.png'),
            alt: ""
          }, null, 8, _hoisted_5)
        ])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})