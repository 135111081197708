import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mask-content flex-column" }
const _hoisted_2 = {
  class: "tk-m-t-18 tk-fs-15",
  style: {"color":"#aaa"}
}

import CustomLoading from '@/components/input/CustomLoading.vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'loading',
  setup(__props, { expose: __expose }) {

const text = ref("")
const setText = (title: string) => {
    text.value = title;
}
__expose({ setText })


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CustomLoading, { class: "tk-w-40 tk-h-40" }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(text.value), 1)
  ]))
}
}

})