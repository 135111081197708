import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tk-fs-14 tk-h-20 flex-row flex-align-center",
  style: {"color":"#808191"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["type", "placeholder"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "h-1 tk-p-t-12 pointer" }
const _hoisted_7 = {
  key: 1,
  class: "position-ab",
  style: {"bottom":"0"}
}
const _hoisted_8 = {
  class: "tk-fs-12",
  style: {"color":"rgb(237, 65, 100)"}
}

import { computed, ref, toRef, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomInput',
  props: {
    modelValue: { default: '' },
    title: { default: '' },
    type: { default: 'text' },
    placeholder: { default: '' },
    rules: {},
    focusColor: { default: 'rgb(85, 112, 134)' },
    color: { default: '#ffffff' },
    placeholderColor: { default: '#999999' },
    fontSize: { default: '14px' },
    canHide: { type: Boolean, default: false },
    showInput: { type: Boolean, default: true }
  },
  emits: ['update:model-value'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

_useCssVars(_ctx => ({
  "53a4c682": (_ctx.color),
  "613ae614": (_ctx.fontSize),
  "6e01ea58": (_ctx.placeholderColor)
}))

const emits = __emit
const props = __props
const inputType = ref(props.type);
const changeValue = (e: Event) => {
    emits('update:model-value', (e.target as HTMLInputElement).value)
}
/// 0 normal 1 focus  2 error
const status = ref(0)
const clickStatus = ref(false)
const error = ref("")

const enterFocus = (sta: boolean) => {
    if (!sta && error.value) return;
    if (sta) {
        clickStatus.value = true;
    }
    status.value = 1;
}
const exitFocus = (sta: boolean) => {
    if (sta && props.rules) {
        error.value = props.rules(props.modelValue) ?? "";
        if (error.value) {
            status.value = 2;
            return;
        }
        if (status.value == 2) {
            status.value = 0;
        }
    }
    if (status.value != 2) {
        if (sta) {
            clickStatus.value = false;
            status.value = 0
        }
        if (clickStatus.value) {
            return;
        }
        clickStatus.value = false;
        status.value = 0
    }
}

__expose({
    validate: () => {
        exitFocus(true);
        return status.value === 0;
    }
})

const changeInputType = () => {
    inputType.value = inputType.value == 'password' ? 'text' : 'password'
}

const showInputType = ref(props.showInput)
const changeShowInput = () => {
    showInputType.value = !showInputType.value;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["position-re", _ctx.rules ? 'tk-p-b-25' : 'tk-p-b-15'])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
          (_ctx.canHide)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "h-1 tk-p-v-3 flex-row flex-center pointer",
                onClick: changeShowInput,
                style: _normalizeStyle([{"transition":"all 0.25s"}, {
                    transform: `rotateZ(${showInputType.value ? 90 : 0}deg)`
                }])
              }, [
                _createElementVNode("img", {
                  class: "h-1 tk-w-34",
                  src: require('@/assets/icon/arrow.png'),
                  alt: ""
                }, null, 8, _hoisted_2)
              ], 4))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex-row flex-align-center", showInputType.value ? 'active' : 'inactive']),
      style: {"transition":"all 0.25s"}
    }, [
      _createElementVNode("div", {
        class: "input-div changeShowInput animate__animated flex-row flex-align-center tk-m-t-12",
        style: _normalizeStyle([{"flex":"1 1 0"}, {
                    borderColor: status.value == 0 ? 'rgb(47, 70, 83)' : status.value === 1 ? _ctx.focusColor : 'rgb(237,65,100)'
                }])
      }, [
        _createElementVNode("input", {
          onChange: changeValue,
          onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (enterFocus(false))),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (exitFocus(false))),
          onFocusin: _cache[2] || (_cache[2] = ($event: any) => (enterFocus(true))),
          onFocusout: _cache[3] || (_cache[3] = ($event: any) => (exitFocus(true))),
          type: inputType.value,
          placeholder: _ctx.placeholder
        }, null, 40, _hoisted_3),
        (_ctx.type === 'password')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: {"margin-left":"auto"},
              class: "pointer h-1 tk-p-v-5",
              onClick: changeInputType
            }, [
              (inputType.value == 'password')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "h-1",
                    src: require('@/assets/icon/eye.png'),
                    alt: ""
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "h-1",
                    src: require('@/assets/icon/eye.png'),
                    alt: ""
                  }, null, 8, _hoisted_5))
            ]))
          : _createCommentVNode("", true)
      ], 4),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "btn")
      ])
    ], 2),
    (error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(error.value), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})