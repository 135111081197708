
export const EventCustomType = {
    /** 窗口變化比較大 */
    EventResizeWindow: "ResizeWindow",
    /** 菜單變化 */
    EventMenuChange: "__Collaps__change_",
    /** reload home */
    EventReloadHome: "__Event_Reload_Home__",
    /** 登錄 */
    LoginToLogin: "__Login_To_Login__",
    /** 註冊 */
    LoginToRegister: "__Login_To_Register__",
    /** 忘記密碼 */
    LoginToForget: "__Login_To_Forget__",

    /** 登錄成功 */
    LoginSuccess: "__Login_Success",
    /** 設置錢包 */
    UserSettingWallet: "User_Setting_Wallet_",

    /** Wallet */
    AccountChangeWallet: "__Accoount_Change_Wallet__",
    /** 斷開網絡鏈接 */
    DisconnectWallet: "__Accoount_Disconnect_Change_Wallet__",
    /** Wallet Sign */
    WalletSignMessage: "__Wallet_Sign_Message__",

    /** 更新用戶信息 */
    UpdateTokenInfo: "__Update_Token_Info__"
}


