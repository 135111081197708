import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomLoading',
  props: {
    color: { default: '#999999' },
    class: { default: 'tk-w-20 tk-h-20' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "3cd32e0a": (_ctx.color)
}))





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["spinner", _ctx.class])
  }, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"spinner-container container1\" data-v-5e4ebe43><div class=\"circle1\" data-v-5e4ebe43></div><div class=\"circle2\" data-v-5e4ebe43></div><div class=\"circle3\" data-v-5e4ebe43></div><div class=\"circle4\" data-v-5e4ebe43></div></div><div class=\"spinner-container container2\" data-v-5e4ebe43><div class=\"circle1\" data-v-5e4ebe43></div><div class=\"circle2\" data-v-5e4ebe43></div><div class=\"circle3\" data-v-5e4ebe43></div><div class=\"circle4\" data-v-5e4ebe43></div></div><div class=\"spinner-container container3\" data-v-5e4ebe43><div class=\"circle1\" data-v-5e4ebe43></div><div class=\"circle2\" data-v-5e4ebe43></div><div class=\"circle3\" data-v-5e4ebe43></div><div class=\"circle4\" data-v-5e4ebe43></div></div>", 3)
  ]), 2))
}
}

})