<template>
    <div class="mask-content flex-column">
        <CustomLoading class="tk-w-40 tk-h-40"></CustomLoading>
        <span class="tk-m-t-18 tk-fs-15" style="color: #aaa;">{{ text }}</span>
    </div>
</template>

<script setup lang="ts">
import CustomLoading from '@/components/input/CustomLoading.vue';
import { ref } from 'vue';

const text = ref("")
const setText = (title: string) => {
    text.value = title;
}
defineExpose({ setText })

</script>


<style lang="scss" scoped></style>